.styled-buttona{
    cursor: pointer;
    width: 100%;
    visibility: visible; 
    color: white;
    display: inline-block;
    width: auto;
    min-width: 140px;
    padding: 10px;
    border: 2px solid darkorange;
    border-radius: 5px;
    background-color: darkorange;
    outline: none;
    text-decoration: none;
}

.styled-buttona:hover {
    text-shadow: none;
    -webkit-transition: background-color 0.2s ease-out, color 0.2s ease-out;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    background-color: #fff;
    color: darkorange;
}


.styled-buttonb{
    cursor: pointer;
    width: 100%;
    visibility: visible; 
    color: white;
    display: inline-block;
    width: 140px;
    padding: 10px;
    border: 2px solid #fff;
    border-radius: 5px;
    text-shadow: 3px 3px 10px #181818;
    background-color: transparent;
    outline: none;
    text-decoration: none;
}

.styled-buttonb:hover {
    text-shadow: none;
    -webkit-transition: background-color 0.2s ease-out, color 0.2s ease-out;
    transition: background-color 0.2s ease-out, color 0.2s ease-out;
    background-color: #fff;
    color: darkorange;
}


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    .styled-buttonb {
        width: auto;
        min-width: 200px;
    }

    .styled-buttona {
        width: auto;
        min-width: 200px;
    }
}