.mobile-nav-wrapper
{
  margin: 0;
  padding: 0;
  visibility: unset;
  color: #cdcdcd;
}

#menuToggle
{
  display: block;
  position: relative;
  left: 0px;
  top:10px;
  z-index: 10;
  -webkit-user-select: none;
  user-select: none;
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 33px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 11; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #fff;
  border-radius: 3px;
  
  z-index: 10;
  
  transform-origin: 16px 0px;
  
  transition: transform 0.3s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.3s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.33s ease;
}

#menuToggle span:first-child
{
  transform-origin: 16px 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 16px 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: translate(-2px, 12px) rotate(225deg);
  background: #fff;
}

#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: translate(-2px, -13px) rotate(-225deg);
}

.mob-nav-slide {
    transition: top 0.2s ease-in-out, visibility 0.2s ease, opacity 0.2s ease;
    height: calc(100% - 110px);
    width: 90vw;
    left: 5vw;
    position: fixed;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 8;
    -webkit-box-shadow: 0px 0px 0px 100vh rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 0px 100vh rgba(0,0,0,0.5);
    box-shadow: 0px 0px 0px 100vh rgba(0,0,0,0.5);
    background-color: #fff;
    border-radius: 5px;
}

.mob-nav-slide-collapsed {
    visibility: hidden;
    opacity: 0;
    top: 400px;
}

.mob-nav-slide-expanded {
    opacity: 1;
    visibility: visible;
    top: 100px;
}

.mob-menu__navigation {
    background-color: #fff;
    margin-bottom: 1.5rem;
}

.mob-menu__navigation {
    list-style-type: none;
    margin: 0;
    padding-left: 0;
}

.mob-menu__navigation li {
    display: list-item;
    text-align: -webkit-match-parent;
}

.mob-menu__navigation ul {
    list-style-type: none;
}

.clearfix:before, .clearfix:after {
    content: " ";
    display: table;
}

.mob-menu__item:first-child {
    padding-top: 0;
}

.mob-menu__item--full-width {
    width: 100%;
}

.mob-menu__item {
    color: #0e202e;
    float: left;
    font-size: 0.875rem;
    font-weight: 900;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 20px;
    text-transform: uppercase;
    width: 50%;
}

.mob-menu__link, .mob-menu__link:visited {
    color: #fa6400;
    text-decoration: none;
    cursor: pointer;
}

.mob-menu__submenu-holder {
    font-weight: 700;
    letter-spacing: 1.5px;
    width: 100%;
}

.mob-menu__submenu {
    border-top: 1px solid #bac1c8;
    border-bottom: 1px solid #bac1c8;
    padding: 7px 15px;
    margin-top: .75rem;
    margin-left: -5px;
    margin-right: 7px;
}

.mob-menu__submenu-item {
    color: #0e202e;
    font-weight: 700;
    float: left;
    margin-top: 5px;
    margin-bottom: 10px;
    padding: 0;
    text-transform: none;
    width: 50%;
}



@media (min-width: 576px) { 
    #menuToggle {
        left: 20px;
    }
}

@media (min-width: 992px) { 
    .mobile-nav-wrapper {
        display: none;
    }
}