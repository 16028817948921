.main-video-wemp {
	background-image: url("https://lpdf.in/images/empowerment/back1.jpg");
	background-size: contain;
    background-repeat: repeat;
	background-position: left;
	height: auto;
}

.main-video-container-wemp {
	color: white;
	padding-left: 5%;
	padding-right: 5%;
	text-align: center;
}

.main-video-container-wemp h3 {
	margin-bottom: 8vh;
	margin-top: 5vh;
}

.main-video-container-wemp > hr{
	background-color: white;
	height: 2px;
	width: 25%;
}

.main-video-container-wemp-inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}

.wemp-iframe {
	width: 80vw;
	height: 50vw;
	border-radius: 10px;
	margin-left: 0;
	margin-bottom: 0%;
}

.wemp-iframe iframe{
	width: 100%;
	height: 100%;
	border-radius: 2%;
}

.wemp-quote {
  background-color: #fff;
  font-size: 62.5%;

}

.wemp-quote {
  width: 90%;
  margin: 50px auto;
  margin-bottom: 20px;
  margin-top: 20px;
}

.aligned {
	margin-top: -5%;
	margin-bottom: -2%;
}

.quoted {
  font-size: 1.6em;
  color: rgba(255,255,255,.5);
  font-family: courier, mono-space;
}

.main-video-container-wemp .main-video-container-wemp-inner:nth-child(5) {
	display: flex;
	flex-direction: column-reverse;
}

.wemp-text p b {
	color: darkorange;
}

.wemp-article-header {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'Pacifico';
    color: darkorange;
    text-align: center;
    margin-top: 20px;
    font-size: 3.5rem;
}

.wemp-article-header::after {
    content: '';
    border-top: 5px solid darkorange;
    margin-top: 2%;
    width: 10%;
    margin-left: 45%;
}

.wemp-article-wrapper {
    margin-bottom: 5%;
}

.wemp-article-text {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.wemp-article-text p {
    column-count: 1;
}

.wemp-article-text p:nth-child(1)::first-letter {
    font-size: 2.5em;
    color: darkorange;
    margin-left: 20px;
    margin-right: 8px;
    line-height: 8px;
    font-family: 'Pacifico';
}

.wemp-article-text p:nth-child(3)::first-letter {
    font-size: 2.5em;
    color: darkorange;
    margin-left: 20px;
    margin-right: 8px;
    line-height: 8px;
    font-family: 'Pacifico';
}

.wemp-article-text b {
    color: darkorange;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

	.main-video-container-wemp .main-video-container-wemp-inner:nth-child(5) {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
	}

	.main-video-container-wemp-inner {
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
	}

	.wemp-text-wrapper {
		width: 50vw;
	}

	.wemp-iframe {
		width: 40vw;
		height: 25vw;
		max-width: 500px;
		max-height: 300px;
		border-radius: 2%;
	}

	.wemp-text {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.wemp-text-left {
		padding-left: 5vw;
	}

	.wemp-text-right {
		padding-right: 5vw;
	}
}

@media (min-width: 992px) {
	.wemp-article-text p {
	    column-count: 2;
	    column-gap: 2vw;
	}
	.wemp-article-img {
		max-height: 300px;
		width: auto;
	}
}

@media (min-width: 1400px) {
	.wemp-quote {
	    width: 70%;
	}
	.main-video-container-wemp {
		padding-left: 10%;
		padding-right: 10%;
	}
}