.ebcontent-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.blog_a1{
	cursor: pointer;
	color: black;
	margin: 10px 0px 10px 0px;
	font-size: 0.9rem;
	float: right;
	padding: 0;
	font-weight: 500;
	font-family: 'lato';
    text-decoration: none;
    outline: 0;
    background-color: transparent;
    border: none;
	transition: 0.2s ease-in-out;
}

.blog_a1:hover{
    color: #ff7700;
    border: none;
    outline: 0;
}

.blog_a1:focus{
    color: #ff7700;
    border: none;
    outline: 0;
}

.blog_a1:active{
    color: #ff7700;
    border: none;
    outline: 0;
}

.blog_a2 {
	max-width: 600px;
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
}

.blog_a2 iframe {
	border-radius: 5px;
	align-self: center;
	width: 280px;
	height: 158px;
}

.blog-title {
	display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'lato';
    font-weight: 600;
    color: darkorange;
    font-size: 2rem;
    text-align: center;
    align-items: center;
    line-height: 3rem;
}

.blog-title::after {
    content: '';
    border-top: 3px solid darkorange;
    margin-top: 15px;
    width: 100px;
}

.blog-subtitle {
	display: flex;
    flex-direction: column;
    position: relative;
    color: grey;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: center;
}

.eb-sideitem-wrapper {
	display: flex;
	flex-direction: column;
	height: auto;
}

.ebside-items {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

.eb-side-img {
	width: auto;
	height: 100%;
	width: 180px;
	margin-bottom: 20px;
	border-radius: 4px;
}

.eb-text-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
}

@media (min-width: 450px) {
	.blog_a2 iframe {
		width: 400px;
		height: 230px;
	}

	.eb-sideitem-wrapper {
		flex-direction: row;
	}

	.eb-side-img {
		margin-bottom: 0px;
	}
}

@media (min-width: 768px) {
	.blog_a2 iframe {
		width: 450px;
		height: 255px;
	}
}

@media (min-width: 992px) {
	.ebcontent-wrapper {
		flex-direction: row;
		align-items: flex-start;
		justify-content: center;
	}
}

@media (min-width: 1300px) {
	.blog_a2 iframe {
		width: 600px;
		height: 340px;
	}
}