.card-a-types-card {
	border-radius: 8px;
	height: auto;
	overflow: hidden;
	position: relative;
	-webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.2); 
	box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
	max-width: 325px;
	transition: 0.3s ease;
}

.card-a-types-card:after{
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 25px solid darkorange;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    transform: rotate(-45deg);
    transform-origin: 80% -30% 0;
}

.card-a-types-card:hover {
	box-shadow: 0 4px 20px 0 rgba(0,0,0,0.2);
	transform: translateY(-7px);
}


.card__container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: auto;
}
  
.card {
	position: relative;
	background: white;
	height: auto;
	color: #181818;
	overflow: hidden;
}

.card__image-container {
	margin: -2rem -2rem 1rem -2rem;
	
}

.card-image {
	display: block;
	margin: auto;
	width: 100%;
	height: auto;
}

.card__content {
	margin-top: 0vw;
}

.card__title {
	color: darkorange;
	text-align: left;
	display: flex;
	flex-direction: column;
	font-family: 'lato';
	font-weight: 600;
	font-size: 1.7em;
	line-height: 1.5em;
}

.card__title::after {
	content: '';
	margin-top: 15px;
	border-top: 3px solid darkorange;
	width: 20%;
}

.card__sub__title {
	color: #666;
	font-family: 'lato';
	font-weight: 500;
	font-size: 1.3em;
	line-height: 2rem;
}

.card__content div b {
	color: darkorange;
}

.card__svg {
	position: absolute;
	left: 0;
	top: 20vw;
}

.card__text p b {
	color: darkorange;
}



.c-youtube-video--showcase__link {
    border-bottom: none;
    justify-content: center;
    margin: auto;
    position: relative;
}
.o-block-grid>* {
    margin-bottom: 2.43902439%;
}
.t-contains-reverse-links .t-default-link, .t-default-link{
    color: #fa6400;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid #fa6400;
    word-break: break-word;
}
.u-rect-ice-blue-hover rect {
    fill: #fa6400!important;
}
.u-visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    border: 0;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
}
svg:not(:root) {
    overflow: hidden;
}
.c-youtube-video--showcase__icon {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 30px;
    z-index: 2;
}
.c-youtube-video--showcase__video {
    box-shadow: 0 0 1.5rem rgba(105,105,105,.5);
    max-width: 870px;
    width: 100%;
}
.c-youtube-video--showcase__image, .c-youtube-video--showcase__video {
    min-width: 100%;
}
.u-padding-top--medium {
    padding-top: 1rem!important;
}
.u-margin-top--x-large {
    margin-top: 2rem!important;
}
.o-block-grid {
    padding-top: 0;
}
.o-block-grid {
    display: grid;
    grid-gap: 1rem 2.43902439%;
    justify-content: center;
}
.o-block-grid {
    margin-bottom: 1rem;
    list-style-type: none;
}
.c-yt-player.v--translucent-bg {
    background-color: rgba(0,0,0,.8);
}
.c-yt-player {
    display: none;
    outline: 0;
    background-color: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9000;
    text-align: center;
}

.card_a_button{
	cursor: pointer;
	color: darkorange;
	margin: 15px 0px 10px 0px;
	font-size: 0.9rem;
	float: left;
	padding: 5px 20px 5px 20px;
	font-weight: 600;
	font-size: 0.85em;
	font-family: 'lato';
    text-decoration: none;
    outline: 0;
    background-color: transparent;
    border: 1px solid darkorange;
    border-radius: 5px;
	transition: 0.2s ease-in-out;
}

.card_a_button:hover, .card_a_button:focus, .card_a_button:active {
    color: white;
    background-color: darkorange;
    outline: 0;
    text-decoration: none;
}

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 360px) {  
	.card__svg {
		top: 70px;
	}
}

@media (min-width: 576px) {  
	.card__content {
		margin-top: 10px;
	}

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  
    .card__content {
    	margin-top: 0px;
    }
    
}

@media (min-width: 992px) {
	.card-a-types-card {
		min-width: 270px;
	}
}


@media (min-width: 1200px) {
	.card-a-types-card {
		min-width: 300px;
	}
}

@media (min-width: 1300px) {
	.card-a-types-card {
		width: 315px;
		min-width: 315px;
	}
}


@media (min-width: 1600px) {
	.card-a-types-card {
		width: 350px;
		min-width: 350px;
	}
}

@media (min-width: 1800px) {
	.card-a-types-card {
		width: 350px;
		min-width: 350px;
	}
}
