.main-video-dsr {
	background-image: url("https://lpdf.in/images/dsr/back1.jpg");
	background-size: contain;
    background-repeat: repeat;
	background-position: left;
	height: auto;
}

.main-video-container-dsr {
	color: white;
	padding-left: 5%;
	padding-right: 5%;
	text-align: center;
}

.main-video-container-dsr hr{
	background-color: white;
	height: 2px;
	width: 20%;
}

.main-video-container-dsr-inner{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	text-align: left;
}

.dsr-list {
	list-style: none;
	padding: 0;
}

.dsr-list li {
	margin-bottom: 10px;
	padding-left: 20px;
	position: relative;
}

.dsr-list li:before {
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	height: 10px;
	width: 10px;
	border: 1px solid cyan;
	border-width: 2px 2px 0 0;
	transform: rotate(45deg);
  }

.dsr-iframe {
	width: 80vw;
	height: 60vw;
	border-radius: 2%;
	margin-left: 0;
}

.dsr-iframe iframe{
	width: 100%;
	height: 100%;
	border-radius: 2%;
}



.faq {
	margin-bottom: 5vw;
	padding-left: 7.5%;
	padding-right: 7.5%;
}

.faq-header {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-top: -50px;
	margin-bottom: 2vw;
}

.faq-header::after {
	width: 10%;
	content: '';
	border-top: 2px solid black;
	margin-top: 0.5em;
	margin-left: 45%;
}

.accordion {
	background-color: #eee;
	color: #444;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: left;
	outline: none;
	transition: 0.4s;
}

.curr, .accordion:hover {
	background-color: #ccc;
}

.accordion:after {
	content: '\002B';
	color: #777;
	font-weight: bold;
	float: right;
	margin-left: 5px;
}

.curr:after {
	content: "\2212";
}

.panel {
	padding: 0 18px;
	background-color: white;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}


.panel ul li {
	margin-bottom: 10px;
}


/* Medium devices (tablets, 768px and up) */
@media (min-width: 992px) {
	.main-video-container-dsr-inner{
		flex-direction: row;
		align-items: center;
		justify-content: space-around;
	}
	.dsr-iframe {
		width: 40vw;
		height: 30vw;
		margin-left: 2%;
	}
	.dsr-list li:before {
		top: 12px;
	}
	.dsr-list li {
		font-weight: 500;
		margin-bottom: 0px;
	}
	.dsr-list {
		padding-left: 40px;
	}
}

@media (min-width: 1400px) {
	.main-video-container-dsr {
		padding-left: 10%;
		padding-right: 10%;
	}
}