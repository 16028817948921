.three-g{
	background-color: white;
	height: auto;
	background-position: center top;
}

.three-g-one {
	background-image: url("https://lpdf.in/images/3G/three-g-one-bw.jpg");
	background-size: cover;
    background-repeat: no-repeat;
}

.three-g-two {
	background-image: url("https://lpdf.in/images/3G/three-g-two-bw.jpg");
	background-size: cover;
	height: 800px;
    background-repeat: repeat;
}

.three-g-three {
	background-image: url("https://lpdf.in/images/3G/three-g-three-bw.jpg");
	background-size: cover;
    background-repeat: no-repeat;
}

.main-card {
	display: flex;
	flex-direction: column;
	height: auto;
	margin-left: 7.5%;
	margin-top: 45vw;
	margin-right: 7.5%;
	margin-bottom: 4%;
	border-top-left-radius: 0%;
	border-top-right-radius: 0%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	padding: 5% 7.5%;
	overflow: visible;
	-webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.2); 
	box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
	background-color: rgba(255,255,255,1);
	justify-content: center;
	align-items: center;
	position: relative;
}

.main-card h2 {
	color: darkorange;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top: 3em;
}

.main-card h2::after {
	content: '';
	border-top: 4px solid darkorange;
	margin-top: 0.7em;
	margin-bottom: 2em;
	width: 25%;
}

.main-card-img {
	position: absolute;
	height: 60vw;
	top: -48vw;
	width: 100%;
	overflow: hidden;
	box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.3);
	border-radius: 10px;
}

.main-card p {
	text-indent: 2em;
}

.main-card p:before {
	color: darkorange;
	content: '\201c';
	font-size: 4em;
	position: absolute;
	top: 120px;
	left: -10px;
}


.main-card p b{
    color: darkorange;
    font-weight: 700;
}

.main-card::after {
	content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 25px solid darkorange;
    border-left: 25px solid transparent;
	border-right: 25px solid transparent;
    transform: rotate(-45deg);
    opacity: 0;
    transform-origin: 80% -30% 0;
}

.main-card-mirror {
	border-top-left-radius: 0%;
	border-top-right-radius: 0%;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	margin-left: 7.5%;
	margin-top: 55vw;
	margin-right: 7.5%;
	margin-bottom: 10%;
	position: absolute;
}

.main-card-mirror::before {
	content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 25px solid darkorange;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    transform: rotate(45deg);
	transform-origin: 25% -20% 0;
	opacity: 0;
}

.main-card-mirror::after {
	opacity: 0;
}

.main-card-img-gau {
	background-image: url("https://lpdf.in/images/3G/three-g-one.jpg");
	background-size: cover;
    background-repeat: no-repeat;
	background-position-x: 35%;
	border: 10px solid white;
}

.main-card-img-ganga {
	background-image: url("https://lpdf.in/images/3G/three-g-ganga-mobile.jpg");
	background-size: cover;
    background-repeat: no-repeat;
	background-position-x: 45%;
	border: 10px solid darkorange;
}

.main-card-img-gita {
	background-image: url("https://lpdf.in/images/3G/three-g-three.jpg");
	background-size: cover;
    background-repeat: no-repeat;
	background-position-x: 20%;
	border: 10px solid white;
}

.card_custom_button{
	cursor: pointer;
	color: white;
	margin: 30px 0px 10px 0px;
	float: left;
	padding: 5px 20px 5px 20px;
	font-weight: 600;
	font-size: 1.1em;
	font-family: 'lato';
    text-decoration: none;
    outline: 0;
    background-color: darkorange;
    border: 2px solid darkorange;
    border-radius: 5px;
	transition: 0.2s ease-in-out;
}

.card_custom_button:hover, .card_custom_button:focus, .card_custom_button:active {
    color: darkorange;
    background-color: white;
    outline: 0;
    text-decoration: none;
}

@media (min-width: 576px) {  
	.main-card p:before {
		left: 0px;
	}
	.three-g-two {
		height: 870px;
	}
	.main-card-img {
		height: 50vw;
	}
	.main-card h2 {
		margin-top: 2em;
	}
}


/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  
	
	.main-card p:before {
		left: 10px;
	}

	.three-g-two {
		height: 1000px;
	}
}


@media (min-width: 992px) {
	.three-g-two {
		height: 900px;
	}
	.main-card-img-ganga {
		background-image: url("https://lpdf.in/images/3G/three-g-two.jpg");	
	}
	.main-card {
		margin-top: 8%;
		margin-left: 50%;
		margin-bottom: 8%;
		border-top-left-radius: 0px;
		border-top-right-radius: 10px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	.main-card-img {
		height: 120%;
		width: 100%;
		left: -100%;
		top: -10%;
	}
	.main-card-mirror {
		margin-Left: 7.5%;
		margin-Right: 50%; 
		margin-Top: 12%;
		margin-Bottom: 12%;
		border-top-left-radius: 10px;
		border-top-right-radius: 0px;
		border-bottom-left-radius: 0px;
		border-bottom-right-radius: 0px;
	}
	.main-card-img-ganga {
		left: 100%;
	}
	.main-card::after {
		opacity: 1;
	}
	.main-card h2 {
		margin-top: 0;
	}
	.main-card-mirror::before {
		opacity: 1;
	}
	.main-card-mirror::after {
		opacity: 0;
	}
	.main-card p:before {
		left: 30px;
	}
}

@media (min-width: 1200px) {
	.main-card p:before {
		left: 40px;
	}
	.main-card-img-ganga {
		background-position-x: 50%;
	}
}

@media (min-width: 1400px) {
	.main-card p:before {
		left: 60px;
	}
}

@media (min-width: 1500px) {
	.three-g-two {
		background-size: contain;
		background-position: right top;
	}
}
@media (min-width: 1600px) {
	.main-card p:before {
		left: 80px;
	}
	.three-g-two {
		height: 950px;
	}

}