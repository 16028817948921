
/*Carousel*/
.carousel-section {
	width: 100%;
	background-color: white;
}

.carousel-wrapper-left {
	display: flex;
	flex-direction: column;
	position: relative;
}

.carousel-wrapper-right {
	display: flex;
	flex-direction: column;
	position: relative;
}

.carousel-main {
	padding-left: calc(10vw - 15px);
	padding-right: calc(10vw - 25px);
	height: 95vw;
    overflow: hidden;
}

.carousel-main img {
	border-radius: 5%;
	border: solid 10px white;
	-webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.25); 
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.25);
	position: absolute;
}

.top-left-all {
	height: 80vw;
	margin-left: 15px;
	margin-top: 15px;
	position: relative;
}

.top-left {
	z-index: 2;
	opacity: 1;
	left: 0vw;
	top: 0vw;
	width: 80vw;  
	height: auto;
}

.top-left-below {
	z-index: 1;
	opacity: 0;
	left: 40vw;
	top: 40vw;
	width: 40vw;
	height: auto;
}

.top-left-above {
	z-index: 1;
	opacity: 0;
	left: 0vw;
	top: 0vw;
	width: 40vw; 
	height: auto;
}

@keyframes moveTopLeftBelow {
	100% {
		z-index: 2;
		left: 0vw;
		top: 0vw;
		width: 80vw;  
		height: auto;
		opacity: 1;
	}
}

@keyframes moveTopLeft {
	100% {
		z-index: 1;
		left: 0vw;
		top: 0vw;
		width: 40vw; 
		height: auto;
		opacity: 0;
	}
}

@keyframes moveTopLeftAbove {
	100% {
		z-index: 1;
		left: 40vw;
		top: 40vw;
		width: 40vw;
		height: auto;
		opacity: 0;
	}
}


.next-button-action-topLeft {
	animation: moveTopLeft 0.75s ease;
	animation-fill-mode: forwards;
}

.next-button-action-topLeftBelow {
	animation: moveTopLeftBelow 0.75s ease;
	animation-fill-mode: forwards;
}

.next-button-action-topLeftAbove {
	animation: moveTopLeftAbove 0.75s ease;
	animation-fill-mode: forwards;
}




.greybox-div {
	background-color: white;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.greybox-div h2 {
	display: flex;
	flex-direction: column;
	text-align: left;
}


.greybox-div h2::after {
	content: '';
	border-top: 2px solid black;
	margin-top: 1em;
	width: 20%;
}

.greybox-div p {
	text-align: justify;
}

.greybox-div p b {
	color: darkorange;
}


/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 544px) {  


	.carousel-main {
		padding-left: calc(50vw - 215px);
		padding-right: calc(50vw - 225px);
		height: 500px;
	    overflow: hidden;
	}

	.top-left-all {
		height: 400px;
		margin-left: 15px;
		margin-top: 15px;
		position: relative;
	}

	.top-left {
		z-index: 2;
		opacity: 1;
		left: 0px;
		top: 0px;
		width: 400px;
		height: auto;
	}

	.top-left-below {
		z-index: 1;
		opacity: 0;
		left: 200px;
		top: 200px;
		width: 200px;
		height: auto;
	}

	.top-left-above {
		z-index: 1;
		opacity: 0;
		left: 0px;
		top: 0px;
		width: 200px;
		height: auto;
	}

	@keyframes moveTopLeftBelow {
		100% {
			z-index: 2;
			left: 0px;
			top: 0px;
			width: 400px; 
			height: auto;
			opacity: 1;
		}
	}

	@keyframes moveTopLeft {
		100% {
			z-index: 1;
			left: 0px;
			top: 0px;
			width: 200px; 
			height: auto;
			opacity: 0;
		}
	}

	@keyframes moveTopLeftAbove {
		100% {
			z-index: 1;
			left: 200px;
			top: 200px;
			width: 200px;
			height: auto;
			opacity: 0;
		}
	}
}

@media (min-width: 576px) { 

}
/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  
	.carousel-wrapper-left {
		display: flex;
		flex-direction: row-reverse;
		position: relative;
		align-items: center;
	}

	.carousel-wrapper-right {
		display: flex;
		flex-direction: row;
		position: relative;
		align-items: center;
	}

	.greybox-div-left-carousel {
		margin-left: 30vw;
		margin-right: 2rem;
	}

	.greybox-div-right-carousel {
		width: 56.5vw;
		margin-right: 1rem;
		margin-left: 1rem;
	}


	.carousel-main-left {
		height: 35vw;
		width: 35vw;
		padding-left: 3rem;
		padding-right: 0px;
	    overflow: visible;
	}

	.carousel-main-right {
		height: 35vw;
		width: 35vw;
		padding-right: 0rem;
		padding-left: 0px;
	    overflow: visible;
	}

	.top-left-all {
		height: 30vw;
		margin-left: 15px;
		margin-top: 15px;
		position: relative;
	}

	.top-left {
		z-index: 2;
		opacity: 1;
		left: 0px;
		top: 0px;
		width: 30vw;
		height: auto;
	}

	.top-left-below {
		z-index: 1;
		opacity: 0;
		left: 15vw;
		top: 15vw;
		width: 15vw;
		height: auto;
	}

	.top-left-above {
		z-index: 1;
		opacity: 0;
		left: 0px;
		top: 0px;
		width: 15vw;
		height: auto;
	}

	@keyframes moveTopLeftBelow {
		100% {
			z-index: 2;
			left: 0px;
			top: 0px;
			width: 30vw; 
			height: auto;
			opacity: 1;
		}
	}

	@keyframes moveTopLeft {
		100% {
			z-index: 1;
			left: 0px;
			top: 0px;
			width: 30vw; 
			height: auto;
			opacity: 0;
		}
	}

	@keyframes moveTopLeftAbove {
		100% {
			z-index: 1;
			left: 15vw;
			top: 15vw;
			width: 15vw;
			height: auto;
			opacity: 0;
		}
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 
	.carousel-main-left {
		padding-left: 5rem;
		padding-right: 0px;
		height: 325px;
		width: 325px;
		padding-right: 0px;
	    overflow: visible;
	}

	.carousel-main-right {	
		height: 325px;
		width: 325px;
		padding-right: 0rem;
		padding-left: 0px;
	    overflow: visible;
	}

	.greybox-div-left-carousel {
		margin-left: 300px;
		margin-right: 2rem;
	}

	.greybox-div-right-carousel {
		width: calc(92vw - 400px);
		margin-left: 3rem;
		margin-right: 1.5rem;
	}

	.top-left-all {
		height: 300px;
		margin-left: 15px;
		margin-top: 15px;
		position: relative;
	}

	.top-left {
		z-index: 2;
		opacity: 1;
		left: 0px;
		top: 0px;
		width: 300px;
		height: auto;
	}

	.top-left-below {
		z-index: 1;
		opacity: 0;
		left: 150px;
		top: 150px;
		width: 150px;
		height: auto;
	}

	.top-left-above {
		z-index: 1;
		opacity: 0;
		left: 0px;
		top: 0px;
		width: 150px;
		height: auto;
	}

	@keyframes moveTopLeftBelow {
		100% {
			z-index: 2;
			left: 0px;
			top: 0px;
			width: 300px; 
			height: auto;
			opacity: 1;
		}
	}

	@keyframes moveTopLeft {
		100% {
			z-index: 1;
			left: 0px;
			top: 0px;
			width: 150px; 
			height: auto;
			opacity: 0;
		}
	}

	@keyframes moveTopLeftAbove {
		100% {
			z-index: 1;
			left: 150px;
			top: 150px;
			width: 150px;
			height: auto;
			opacity: 0;
		}
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  


}


@media (min-width: 1400px) {

    .greybox-div-left-carousel {
		margin-right: 10%;
	}

	.greybox-div-right-carousel {
		width: calc(77vw - 400px);
		margin-left: 10%;
		margin-right: 1rem;
	}

	.carousel-main-left {
		padding-left: 12%;
		padding-right: 5%;
	}

	.carousel-main-right {
		padding-right: 10%;
		padding-left: 4%;
	}
}


@media (min-width: 1700px) {
    .lpdf-header-wrapper {
        padding-left: 12rem;
        padding-right: 12rem;
    }
}

