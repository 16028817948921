.main-video {
	background-image: url("https://lpdf.in/images/ayushman/wall1.jpg");
	background-size: cover;
    background-repeat: no-repeat;
	background-position: left;
	height: auto;
}

.main-video-container {
	height: auto;
	margin-left: 7.5%;
	margin-right: 7.5%;
	color: white;
	overflow: hidden;
	border-radius: 0%;
    text-align: center;
}

.main-video-container hr {
    background-color: white;
    height: 2px;
    width: 20%;
}


.iframe-container {
    position: relative;
    width: 100%;
	padding-bottom: 60%;
	margin-top: 5%;
}

.main-video-container iframe {
    position: absolute;
    left: 0;
    width: 100%;
	height: 100%;
	max-width: 768px;
	max-height: 438px;
	border-radius: 10px;
}



@media (min-width: 922px) { 
	.main-video-container iframe { 
		margin-left: calc(50% - 384px);
	}

	.iframe-container {
		padding-bottom: 438px;
	}
}
