/*     Generated by Zeplin
    Copyright (C) 2020 - present Zeplin

    Licensed under the Apache License, Version 2.0 (the "License");
    you may not use this file except in compliance with the License.
    You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

    Unless required by applicable law or agreed to in writing, software
    distributed under the License is distributed on an "AS IS" BASIS,
    WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
    See the License for the specific language governing permissions and
    limitations under the License.
 */

:root {
  --white: #fafafa;
  --black: #212121;
  --greyish-brown: #424242;
  --brownish-grey: #616161;
  --brownish-grey-two: #757575;
  --white-two: #ffffff;
  --brown-grey: #9e9e9e;
  --bright-orange: #fa6400;
  --very-light-pink: #e0e0e0;
  --very-light-pink-two: #eeeeee;
  --white-three: #f5f5f5;
  --very-light-pink-three: #bdbdbd;
}

@font-face {
  font-family: 'Kulim Park';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Kulim Park Kulim Park Regular'), local('KulimPark-Regular'), url(https://fonts.gstatic.com/s/kulimpark/v1/fdN79secq3hflz1Uu3IwhFks5A.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Kulim Park';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Kulim Park Kulim Park SemiBold'), local('KulimPark-SemiBold'), url(https://fonts.gstatic.com/s/kulimpark/v1/fdN49secq3hflz1Uu3IwjIYI8alQZA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Kulim Park';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Kulim Park Kulim Park Bold'), local('KulimPark-Bold'), url(https://fonts.gstatic.com/s/kulimpark/v1/fdN49secq3hflz1Uu3IwjOIJ8alQZA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwiPGQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.H1-78ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.H1-78ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.H1-78ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.H1-78ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.H1-78ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.H1-78ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.H1-78ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.H1-78ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(42px + 1.8vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.H1-78ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.H1-78ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.H1-78ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.H1-78ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.H1-78ptrightwhite-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.H1-78ptrightmedium-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.H1-78ptrightlight-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.H1-78ptrightdark-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.H1-78ptleftwhite-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.H1-78ptleftmedium-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.H1-78ptleftlight-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.H1-78ptleftdark-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.H1-78ptcenterwhite-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.H1-78ptcentermedium-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.H1-78ptcenterlight-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.H1-78ptcenterdark-lato {
  font-family: Lato;
  font-size: 78px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.H2-60ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.H2-60ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.H2-60ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.H2-60ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.H2-60ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.H2-60ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.H2-60ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.H2-60ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.H2-60ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.H2-60ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.H2-60ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.H2-60ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.H2-60ptrightwhite-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.H2-60ptrightmedium-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.H2-60ptrightlight-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.H2-60ptrightdark-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.H2-60ptleftwhite-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.H2-60ptleftmedium-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.H2-60ptleftlight-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.H2-60ptleftdark-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.H2-60ptcenterwhite-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.H2-60ptcentermedium-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.H2-60ptcenterlight-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.H2-60ptcenterdark-lato {
  font-family: Lato;
  font-size: calc(40px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.H3-48ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.H3-48ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.H3-48ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.H3-48ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.H3-48ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(30px + 1.5vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.H3-48ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.H3-48ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.H3-48ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(34px + 1vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.H3-48ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.H3-48ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.H3-48ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.H3-48ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(34px + 1vw);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.H3-48ptrightwhite-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.H3-48ptrightmedium-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.H3-48ptrightlight-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.H3-48ptrightdark-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.H3-48ptleftwhite-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.H3-48ptleftmedium-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.H3-48ptleftlight-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.H3-48ptleftdark-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.H3-48ptcenterwhite-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.H3-48ptcentermedium-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.H3-48ptcenterlight-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.H3-48ptcenterdark-lato {
  font-family: Lato;
  font-size: 48px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Subheading-1bold-36ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Subheading-1bold-36ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Subheading-1bold-36ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Subheading-1bold-36ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Subheading-1bold-36ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Subheading-1bold-36ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.Subheading-1bold-36ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Subheading-1bold-36ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.Subheading-1bold-36ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Subheading-1bold-36ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Subheading-1bold-36ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Subheading-1bold-36ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Subheading-1bold-36ptrightwhite-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Subheading-1bold-36ptrightmedium-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Subheading-1bold-36ptrightlight-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Subheading-1bold-36ptrightdark-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Subheading-1bold-36ptleftwhite-lato {
  font-family: Lato;
  font-size: calc(20px + 0.4vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Subheading-1bold-36ptleftmedium-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.Subheading-1bold-36ptleftlight-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Subheading-1bold-36ptleftdark-lato {
  font-family: Lato;
  font-size: calc(20px + 0.4vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  word-spacing: 8px;
  color: #181818;
}

.Subheading-1bold-36ptcenterwhite-lato {
  font-family: Lato;
  font-size: calc(26px + 0.5vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Subheading-1bold-36ptcentermedium-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Subheading-1bold-36ptcenterlight-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Subheading-1bold-36ptcenterdark-lato {
  font-family: Lato;
  font-size: calc(20px + 0.4vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Subheading-2regular-36ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Subheading-2regular-36ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Subheading-2regular-36ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Subheading-2regular-36ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Subheading-2regular-36ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Subheading-2regular-36ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.Subheading-2regular-36ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Subheading-2regular-36ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.Subheading-2regular-36ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Subheading-2regular-36ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Subheading-2regular-36ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Subheading-2regular-36ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Subheading-2regular-36ptrightwhite-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Subheading-2regular-36ptrightmedium-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Subheading-2regular-36ptrightlight-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Subheading-2regular-36ptrightdark-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Subheading-2regular-36ptleftwhite-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Subheading-2regular-36ptleftmedium-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.Subheading-2regular-36ptleftlight-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Subheading-2regular-36ptleftdark-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.Subheading-2regular-36ptcenterwhite-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Subheading-2regular-36ptcentermedium-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Subheading-2regular-36ptcenterlight-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Subheading-2regular-36ptcenterdark-lato {
  font-family: Lato;
  font-size: calc(24px + 1.10vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Subtitle-1bold-26ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Subtitle-1bold-26ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Subtitle-1bold-26ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Subtitle-1bold-26ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Subtitle-1bold-26ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Subtitle-1bold-26ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: #747474;
}

.Subtitle-1bold-26ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Subtitle-1bold-26ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: #181818;
}

.Subtitle-1bold-26ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Subtitle-1bold-26ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Subtitle-1bold-26ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Subtitle-1bold-26ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Subtitle-1bold-26ptrightwhite-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Subtitle-1bold-26ptrightmedium-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Subtitle-1bold-26ptrightlight-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Subtitle-1bold-26ptrightdark-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Subtitle-1bold-26ptleftwhite-lato {
  font-family: Lato;
  font-size: calc(17px + 0.5vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Subtitle-1bold-26ptleftmedium-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  color: #747474;
}

.Subtitle-1bold-26ptleftlight-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Subtitle-1bold-26ptleftdark-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  color: #181818;
}

.Subtitle-1bold-26ptcenterwhite-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Subtitle-1bold-26ptcentermedium-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Subtitle-1bold-26ptcenterlight-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Subtitle-1bold-26ptcenterdark-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Subtitle-2regular-26ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Subtitle-2regular-26ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Subtitle-2regular-26ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Subtitle-2regular-26ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Subtitle-2regular-26ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: calc(17px + 0.5vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Subtitle-2regular-26ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: #747474;
}

.Subtitle-2regular-26ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Subtitle-2regular-26ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size:  calc(17px + 0.5vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  color: #181818;
}

.Subtitle-2regular-26ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Subtitle-2regular-26ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Subtitle-2regular-26ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Subtitle-2regular-26ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Subtitle-2regular-26ptrightwhite-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Subtitle-2regular-26ptrightmedium-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Subtitle-2regular-26ptrightlight-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Subtitle-2regular-26ptrightdark-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Subtitle-2regular-26ptleftwhite-lato {
  font-family: Lato;
  font-size: calc(18px + 0.3vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Subtitle-2regular-26ptleftmedium-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  color: #747474;
}

.Subtitle-2regular-26ptleftlight-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Subtitle-2regular-26ptleftdark-lato {
  font-family: Lato;
  font-size: calc(20px + 0.5vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  color: #181818;
}

.Subtitle-2regular-26ptcenterwhite-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Subtitle-2regular-26ptcentermedium-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Subtitle-2regular-26ptcenterlight-lato {
  font-family: Lato;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Subtitle-2regular-26ptcenterdark-lato {
  font-family: Lato;
  font-size: calc(20px + 0.5vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Bodytext-2bold-16ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Bodytext-2bold-16ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Bodytext-2bold-16ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Bodytext-2bold-16ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Bodytext-2bold-16ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Bodytext-2bold-16ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  color: #747474;
}

.Bodytext-2bold-16ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Bodytext-2bold-16ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  color: #181818;
}

.Bodytext-2bold-16ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Bodytext-2bold-16ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Bodytext-2bold-16ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Bodytext-2bold-16ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Bodytext-2bold-16ptrightwhite-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Bodytext-2bold-16ptrightmedium-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Bodytext-2bold-16ptrightlight-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Bodytext-2bold-16ptrightdark-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Bodytext-2bold-16ptleftwhite-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Bodytext-2bold-16ptleftmedium-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  color: #747474;
}

.Bodytext-2bold-16ptleftlight-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Bodytext-2bold-16ptleftdark-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  color: #181818;
}

.Bodytext-2bold-16ptcenterwhite-lato {
  font-family: Lato;
  font-size: calc(16px + 0.3vw);
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Bodytext-2bold-16ptcentermedium-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Bodytext-2bold-16ptcenterlight-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Bodytext-2bold-16ptcenterdark-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Bodytext-1regular-16ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Bodytext-1regular-16ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Bodytext-1regular-16ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Bodytext-1regular-16ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Bodytext-1regular-16ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Bodytext-1regular-16ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  color: #747474;
}

.Bodytext-1regular-16ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Bodytext-1regular-16ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  color: #181818;
}

.Bodytext-1regular-16ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Bodytext-1regular-16ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Bodytext-1regular-16ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Bodytext-1regular-16ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Bodytext-1regular-16ptrightwhite-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Bodytext-1regular-16ptrightmedium-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Bodytext-1regular-16ptrightlight-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Bodytext-1regular-16ptrightdark-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Bodytext-1regular-16ptleftwhite-lato {
  font-family: Lato;
  font-size: calc(16px + 0.3vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Bodytext-1regular-16ptleftmedium-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  color: #747474;
}

.Bodytext-1regular-16ptleftlight-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Bodytext-1regular-16ptleftdark-lato {
  font-family: Lato;
  font-size: calc(16px + 0.3vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  color: #181818;
}

.Bodytext-1regular-16ptcenterwhite-lato {
  font-family: Lato;
  font-size: calc(16px + 0.3vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Bodytext-1regular-16ptcentermedium-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Bodytext-1regular-16ptcenterlight-lato {
  font-family: Lato;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Bodytext-1regular-16ptcenterdark-lato {
  font-family: Lato;
  font-size: calc(16px + 0.3vw);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Button-15ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Button-15ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Button-15ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Button-15ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.Button-15ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Button-15ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.Button-15ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Button-15ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.Button-15ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Button-15ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Button-15ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Button-15ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Button-15ptrightwhite-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.Button-15ptrightmedium-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.Button-15ptrightlight-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.Button-15ptrightdark-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.Button-15ptleftwhite-lato {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.Button-15ptleftmedium-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.Button-15ptleftlight-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.Button-15ptleftdark-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.Button-15ptcenterwhite-lato {
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.Button-15ptcentermedium-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.Button-15ptcenterlight-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.Button-15ptcenterdark-lato {
  font-family: Lato;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.overline-2bold-14ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.overline-2bold-14ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.overline-2bold-14ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.overline-2bold-14ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.overline-2bold-14ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.overline-2bold-14ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.overline-2bold-14ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.overline-2bold-14ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.overline-2bold-14ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.overline-2bold-14ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.overline-2bold-14ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.overline-2bold-14ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.overline-2bold-14ptrightwhite-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.overline-2bold-14ptrightmedium-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.overline-2bold-14ptrightlight-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.overline-2bold-14ptrightdark-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.overline-2bold-14ptleftwhite-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.overline-2bold-14ptleftmedium-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.overline-2bold-14ptleftlight-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.overline-2bold-14ptleftdark-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.overline-2bold-14ptcenterwhite-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.overline-2bold-14ptcentermedium-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.overline-2bold-14ptcenterlight-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.overline-2bold-14ptcenterdark-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.overline-1regular-14ptrightwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.overline-1regular-14ptrightmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.overline-1regular-14ptrightlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.overline-1regular-14ptrightdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.overline-1regular-14ptleftwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.overline-1regular-14ptleftmedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.overline-1regular-14ptleftlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.overline-1regular-14ptleftdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.overline-1regular-14ptcenterwhite-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.overline-1regular-14ptcentermedium-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.overline-1regular-14ptcenterlight-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.overline-1regular-14ptcenterdark-kulimpark {
  font-family: 'Kulim Park';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.overline-1regular-14ptrightwhite-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: var(--white-two);
}

.overline-1regular-14ptrightmedium-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #747474;
}

.overline-1regular-14ptrightlight-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #aaaaaa;
}

.overline-1regular-14ptrightdark-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: right;
  color: #181818;
}

.overline-1regular-14ptleftwhite-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: var(--white-two);
}

.overline-1regular-14ptleftmedium-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #747474;
}

.overline-1regular-14ptleftlight-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #aaaaaa;
}

.overline-1regular-14ptleftdark-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  color: #181818;
}

.overline-1regular-14ptcenterwhite-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: var(--white-two);
}

.overline-1regular-14ptcentermedium-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #747474;
}

.overline-1regular-14ptcenterlight-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #aaaaaa;
}

.overline-1regular-14ptcenterdark-lato {
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #181818;
}

.blog__text::first-letter {
  font-family: 'lato';
  text-transform: uppercase;
  font-size: 3rem;
  font-weight: 500;
  margin-right: 0;
  line-height: 1rem;
  color: darkorange;
}