.magazine-wrapper {
	margin-top: 80px;
	margin-bottom: 100px;
	padding-left: 7.5%;
	padding-right: 7.5%;
}

.magazine-summary-wrapper {
	display: flex;
	flex-direction: column;
}

.mgsm-anime-wrapper {
	display: flex;
	flex-direction: row;
	position: relative;
}

.mgsm-cards {
	display: flex;
	flex-wrap: wrap;
	padding-left: 30px;
}

.mgsm-card {
	height: 200px;
	box-shadow: 3px 3px 12px 2px rgba(0, 0, 0, 0.3);
	transition: 0.2s;   
}

.mgsm-card-img {
	height: 200px;
	width: auto;
}

.mgsm-img-xl, .mgsm-img-md, .mgsm-img-sm {
	display: none;
}

.mgsm-card:not(:first-child) {
	margin-left: -2rem;
}

.mgsm-card:not(:last-child):hover,
.mgsm-card:not(:last-child):focus-within {
	transform: translateY(-1rem);
}

.mgsm-card:not(:last-child):hover~.mgsm-card,
.mgsm-card:not(:last-child):focus-within~.mgsm-card {
	transform: translateX(2rem);
}

.magazine-summary-text-wrapper {
	display: flex;
	flex-direction: column;
}

.magazine-summary-text::first-letter {
	font-family: 'pacifico';
	text-transform: uppercase;
	font-size: 3rem;
	font-weight: 500;
	margin-right: 0;
	line-height: 1rem;
	color: darkorange;
}

.magazine-summary-text b {
	color: darkorange;
}

.mgsm-button{
	cursor: pointer;
	color: darkorange;
	margin: 15px 0px 10px 0px;
	font-size: 1rem;
	width: 121px;
	align-self: flex-start;
	padding: 5px 20px 5px 20px;
	font-weight: 600;
	font-size: 0.95em;
	font-family: 'lato';
    text-decoration: none;
    outline: 0;
    background-color: transparent;
    border: 2px solid darkorange;
    border-radius: 5px;
	transition: 0.2s ease-in-out;
}

.mgsm-button:hover, .mgsm-button:focus, .mgsm-button:active {
    color: white;
    background-color: darkorange;
    outline: 0;
    text-decoration: none;
}

/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 576px) {
	.mgsm-img-sm {
		display: block;
	}
}

@media (min-width: 768px) {
	.mgsm-img-md {
		display: block;
	}
	
	.mgsm-button {
		align-self: flex-end;
	}
}

@media (min-width: 800px) {
	.mgsm-img-xl {
		display: block;
	}
	.mgsm-cards {
		padding-left: 0px;
	}
}