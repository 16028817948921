.edit-element-wrapper {
  width: 100%;
  height: 100%;
  margin: 0px 0px 30px 0px;
  padding: 0px;
  border: 2px solid #eee;
  border-radius: 0px;
}

.edit-image-wrapper {
  padding: 10px;
}

.edit-element-title {
  margin: 0px;
  padding: 10px;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  background-color: darkorange;
  border-bottom: 1px solid #eee;
}

.edit-element-form {
  margin: 10px;
}
.edit-element-form .edit-element-textarea {
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  width: 100%;
  flex: 1;
  display: flex;
  margin: 10px 0;
  min-height: 100px;
  padding: 10px;
  font-size: 16px;
}
.edit-element-form .edit-element-textarea:focus {
  border-color: darkorange;
  outline: 0;
}
.edit-element-button {
  border: none;
  background: darkorange;
  color: #fff;
  padding: 7px 12px 6px 12px;
  border-radius: 3px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
  font-weight: 600;
  border: 3px solid darkorange;
  font-size: 10px;
}
.edit-element-button:hover {
  background: #fff;
  color: darkorange;
  border: 3px solid darkorange;
}

.edit-element-preview-box {
  position: relative;
  background: #fafafa;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  padding: 40px 15px 20px;
  font-weight: 400;
  margin: 10px 0;
  color: #333;
}

.edit-element-preview-box b{
    color: darkorange;
}

.edit-element-preview-box .edit-element-preview-title {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  font-weight: 600;
  border: 1px solid #ddd;
  border-top: 0;
  border-left: 0;
  border-radius: 5px 0 5px 0;
  padding: 5px 12px;
  letter-spacing: 1px;
}

.upload-image-title {
  padding: 10px;
}

.upload-images {
  height: 100px;
  width: auto;
  display: inline;
  margin-right: 10px;
  margin-bottom: 10px;
}

.editable-header {
  border: 2px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  margin: 33px 30px 33px 30px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow:    0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow:         0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.editable-title {
  margin-bottom: 10px;
  text-align: center;
  font-weight: 400;
  padding: 10px;
  color: #555;
}

.editable-backdrop {
  z-index: 100;
  color: #fff;
}