.homePageHeader {
    background-image: url("https://lpdf.in/images/header/header-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: all;
    overflow: hidden;
}

.allPMTHeader {
    background-image: url("https://lpdf.in/images/pmt/headermob.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    background-attachment: fixed;
    overflow: hidden;
}

.allWelfareHeader {
    background-size: cover;
    background-image: url("https://lpdf.in/images/welfare/header1-mob.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    background-attachment: local;
    overflow: hidden;
}

.allAnandUtsavHeader {
    background-size: cover;
    background-image: url("https://lpdf.in/images/anandutsav/bg1.jpg");
    background-position: 85% top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
}

.allEkadashiHeader {
    background-image: url("https://lpdf.in/images/ekadashi/ekadashi-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x : 50%;
    background-position-y : 0;
    background-attachment: all;
    overflow: hidden;
}

.allDSRHeader {
    background-image: url("https://lpdf.in/images/dsr/dsr_mob.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right top;
    background-attachment: fixed;
    overflow: hidden;
}

.allWomenEmpHeader {
    background-image: url("https://lpdf.in/images/empowerment/top-ban1-mob.jpg");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    overflow: hidden;
}

.allAyushmanHeader {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("https://lpdf.in/images/ayushman/ayushmanBhav_mobile.jpg");
    background-position-x : 35%;
    background-position-y : 0%;
    background-attachment: fixed;
    overflow: hidden;
}

.all3gHeader {
    background-image: url("https://lpdf.in/images/3G/3g-bg-mobile.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x : 50%;
    background-position-y : 0;
    background-attachment: fixed;
    overflow: hidden;
}

.allGaayHeader {
    background-image: url("https://lpdf.in/images/3G/gau-bg-m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x : 50%;
    background-position-y : 0;
    overflow: hidden;
}

.allGitaHeader {
    background-image: url("https://lpdf.in/images/3G/gita-bg-m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x : 50%;
    background-position-y : 0;
    overflow: hidden;
}

.allGangaHeader {
    background-image: url("https://lpdf.in/images/3G/ganga-bg-m.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x : 60%;
    background-position-y : 0;
    overflow: hidden;
}

.allAscentoflifeHeader {
    background-image: url("https://lpdf.in/images/ascentoflife/ascentoflife-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x : 50%;
    background-position-y : 0%;
    background-attachment: fixed;
    overflow: hidden;
}

.allCollaborateHeader {
    background-image: url("https://lpdf.in/images/decoration/collaborate.jpg");
    background-size: cover;
    background-position: 60% top;
    background-repeat: no-repeat;
    background-attachment: local;
    overflow: visible;
}

.allCollaborateHeader .introduction {
    display: none;
}

.allGalleryHeader {
    background-image: url("https://lpdf.in/images/decoration/gallery-bg.jpg");
    background-repeat: repeat;
    background-attachment: fixed;
    overflow: visible;
}

.allGalleryHeader .introduction {
    display: none;
}

.lpdf-header-wrapper {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
}

.lpdf-header-logo {
    height: 70px;
    width: auto;
    position: relative;
    z-index: 11;
    cursor: pointer;
}

.nav-item-seperator {
    opacity: 90%;
    display: none;
    color: white;
    margin-top: 8px;
}

.lpdf-smc-wrapper {    
    max-width: 192px;
}

.lpdf-smc-wrapper div {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
    max-width: 87px;
}

.lpdf-smc img {
    cursor: pointer;
}

.lpdf-smc img:hover {
    transform: rotate(360deg);
    transition: 0.5s ease;
}

.dropdown:hover > .dropdown-menu {
    display: block;
}
.dropdown > .dropdown-toggle:active {
    /*Without this, clicking will make it sticky*/
    pointer-events: none;
}

.lpdf-tab-over {
    display: flex;
    flex-direction: column-reverse;
    color: white;
    opacity: 1;
    font-size: 18px;
}

.lpdf-tab-over:hover {
    color: white;
    opacity: 1;
}

.lpdf-tab-active {
    opacity: 1;
}


.introduction {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden;
    margin-top: 60px;
    min-height: 300px;
    position: relative;
}

.introduction h1 {
    margin-bottom: 70px;
    text-shadow: 3px 3px 20px #474747;
}

.introduction h3 {
    margin-bottom: 90px;
    text-shadow: 3px 3px 10px #181818;
}
.button-link {
    width: 10%;
}

.wavy-divider {
    height: 20vw;
    min-height: 200px;}

.wavy-divider path:nth-child(1){
    opacity: 0.15;
    fill: #fff;
}

.wavy-divider path:nth-child(2){
    opacity: 0.3;
    fill: #fff;
}

.wavy-divider path:nth-child(3){
    opacity: 1;
    fill: #fff;
}

.navbar-toggler{
    display: flex;
    flex-direction: column;
    cursor: pointer;
    height: 38px;
    margin-right: -20px;
}

.navbar-toggler-icon{
    background-color:white;
    margin-top: 3px;
    height: 3px;
    margin-bottom: 3px;
}

.nav-bar__submenu {
    opacity: 0;
    transition: top 0.3s, visibility 0.3s, opacity 0.3s;
    visibility: hidden;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,0.5);
    padding: 10px 25px 10px 25px;
    position: absolute;
    top: 50px;
    z-index: 100;
    list-style-type: none;
    border-top: 5px solid darkorange;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.nav-bar__submenu__first {
    width: 190px;
    margin-left: -50px;
}

.nav-bar__submenu__second {
    width: 380px;
    margin-left: -148px;
}

.nav-bar__submenu::before {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    border-color: transparent transparent white;
    border-style: solid;
    border-width: 10px;
    content: '';
    top: -20px;
}

.nav-bar__submenu li ul {
    list-style-type: none;
    padding-inline-start: 0px;
}

.nav-bar__sub-item {
    float: none;
    height: auto;
    margin: 23px 0;
    text-align: left;
    width: 100%;
}

.nav-bar__sub-link {
    color: #f86400;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    text-decoration: none;
}

.nav-bar__sub-link__sub {
    color: #f86400;
    font-family: 'Lato';
    font-size: 15px;
    font-weight: 700;
    text-transform: capitalize;
    margin-left: 5px;
    text-decoration: none;   
}

.nav-bar__sub-link:focus, .nav-bar__sub-link:hover, .nav-bar__sub-link:visited, .nav-bar__sub-link:link, .nav-bar__sub-link:active {
    text-decoration: none;
}

.nav-bar__sub-link__sub:focus, .nav-bar__sub-link__sub:hover, .nav-bar__sub-link__sub:visited, .nav-bar__sub-link__sub:link, .nav-bar__sub-link__sub:active {
    text-decoration: none;
}

.nav-bar__sub-link:hover {
    animation: colorChanges 0.5s ease;
    animation-fill-mode: forwards;  
}

.nav-bar__sub-link__sub:hover {
    animation: colorChanges 0.5s ease;
    animation-fill-mode: forwards;  
}


@keyframes colorChanges {
    0%{
        color: #f86400;
    }
    100%{
        color: #333;
    }
}

.nav-bar__submenu-holder:hover .nav-bar__submenu {
    opacity: 1;
    visibility: visible;
    top: 55px;
}

.link {
    text-decoration: none;
    color: #fff;
}

.link:focus, .link:hover, .link:visited, .link:link, .link:active {
    text-decoration: none;
    color: #fff;
}

.link-item {
    margin-bottom: 10px;
    cursor: pointer;
}

.link-item:hover .link{
    animation: colorChange 0.5s ease;
    animation-fill-mode: forwards;  
}


/*
::::::::::::::::::::::::::::::::::::::::::::::::::::
Bootstrap 4 breakpoints
*/
/* Small devices (landscape phones, 544px and up) */
@media (min-width: 576px) {  
    .lpdf-header-wrapper {
        padding-left: 3rem;
        padding-right: 3rem;
    }

    .lpdf-header-logo {
        height: 80px;
        width: auto;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {  
    .allAscentoflifeHeader {
        background-position: left top;
    }
    .allWelfareHeader {
        background-image: url("https://lpdf.in/images/welfare/header1.jpg");
        background-position: left top;
    }
    .allDSRHeader {
        background-image: url("https://lpdf.in/images/dsr/dsr.jpg");
    }
    .allGaayHeader {
        background-image: url("https://lpdf.in/images/3G/gau-bg.jpg");
        background-position-x : 0;
    }
    .allGangaHeader {
        background-image: url("https://lpdf.in/images/3G/ganga-bg1.jpg");
    }
    .allGitaHeader {
        background-image: url("https://lpdf.in/images/3G/gita-bg5.jpg");
        background-position-x : 70%;
    }
    .allEkadashiHeader {
        background-position: center top;
    }
    .lpdf-header-wrapper {
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .introduction {
        margin-top: 80px;
    }

    .wavy-divider {
    }

    .introduction h3 {
        width: 60%;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) { 

    .allCollaborateHeader {
        background-image: url("https://lpdf.in/images/decoration/SutraBackground.png");
        background-size: 100vw auto;
    }
    .allPMTHeader {
        background-image: url("https://lpdf.in/images/pmt/header5.jpg");
        background-position: center top;
    }
    .allAyushmanHeader {
        background-image: url("https://lpdf.in/images/ayushman/ayushmanBhav.jpg");
        background-position: center top;
    }
    .all3gHeader {
        background-image: url("https://lpdf.in/images/3G/3g-bg.jpg");
        background-position: left top;
    }
    .allAnandUtsavHeader {
        background-position: right center;
    }
    .allWomenEmpHeader {
        background-image: url("https://lpdf.in/images/empowerment/top-ban1.jpg");
        background-position: center center;
    }

    .lpdf-header-wrapper {
        padding-left: 4rem;
        padding-right: 1rem;
    }

    .lpdf-tab-over {
        opacity: 0.85;
    }

    .lpdf-tab-over::before{
        content: '';
        display: block;
        width: 0;
        height: 2px;
        margin-left: 50%;
        margin-top: 4px;
        background: #fff;
        transition: width .3s, margin-left .3s, opacity .3s;
    }

    .lpdf-tab-over:hover::before {
        width: 100%;
        margin-left: 0%;
    }

    .lpdf-tab-over:focus {
        outline: 0;
        border: none;
    }

    .lpdf-tab-active::before {
        width: 100%;
        margin-left: 0%;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {  
    .allAyushmanHeader {
        background-position: right top;
    }

    .lpdf-header-wrapper {
        padding-left: 6rem;
        padding-right: 2rem;
    }

    .nav-item-seperator {
        opacity: 0.8;
        display: inherit;
    }

    .introduction {
        margin-bottom: -80px;
    }

}


@media (min-width: 1300px) {

    .lpdf-header-logo {
        height: 110px;
        width: auto;
    }

    .lpdf-header-wrapper {
        padding-left: 8rem;
        padding-right: 6rem;
    }
}

@media (min-width: 1400px) {
    .lpdf-tab-over {
        font-size: calc(18px + 0.15vw);
    }

    .nav-item-seperator {
        margin-top: 11px;
    }

    .nav-bar__submenu__second {
        margin-left: -145px;
    }

    .nav-bar__submenu__first {
        margin-left: -45px;
    }
}

@media (min-width: 1600px) {
    .introduction {
        margin-top: 120px;
        margin-bottom: -120px;
    }
}

@media (min-width: 1700px) {
    .lpdf-header-wrapper {
        padding-left: 12rem;
        padding-right: 12rem;
    }
}

