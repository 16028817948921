.pmt-page-wrapper {
	overflow: hidden;
}

.pmt-top-main-header {
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: 'Pacifico';
    color: darkorange;
    text-align: center;
    font-size: 2.8rem;
    font-weight: 400;
}

.pmt-top-main-header::after {
    content: '';
    border-top: 5px solid darkorange;
    margin-top: 1%;
    width: 10%;
    margin-left: 45%;
}

.pmt-top-main-subheader {
	display: flex;
    flex-direction: column;
    position: relative;
    color: #f88800;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
}

.pmt-video-list {
	list-style: none;
}

.pmt-video-list li {
	margin: 0;
	margin-bottom: 20px;
	padding-left: 1.5em;
	position: relative;
}

.pmt-video-list li b {
	color: #ff8c00;
}

.pmt-video-list li:before {
	content: '';
	height: .4em;
	width: .4em;
	background: #fa6400;
	display: block;
	position: absolute;
	transform: rotate(45deg);
	top: .55em;
	left: 0;
}

.list-and-charges {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.pmt-video-charges {
	list-style: none;
	display: flex;
	flex-direction: column;
	padding: 0;
	justify-content: center;
	border: 3px solid darkorange;
	font-size: 1.2rem;
	width: 300px;
	height: 380px;
	background-color: white;
	border-radius: 25px;	
}

.pmt-video-wrapper {
	max-width: 1400px;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
}

.pmt-video-iframe {
	max-width: 600px;
}

.pmt-video-iframe iframe {
	border-radius: 5px;
	width: 300px;
	height: 170px;
}

.pmt-video-intro-text {
	max-width: 1200px;
}

.pmt-video-intro-text p b {
	color: darkorange;
}

.pmt-recent-wrapper {
	background-color: white;
	margin-top: 150px;
}

.pmt-recent-wrapper-inner {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
}

.card__image-container-pmt {
	margin: -2rem -2rem 3rem -2rem;
	padding-top: 25px;
	height: 140px;
}

.pmt-articles-links {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.pmt-articles-links a {
	text-decoration: none;
	padding: 5px 15px 5px 15px;
	margin-top: 16px;
	border-radius: 50px;
	cursor: pointer;
	color: darkorange;
	background-color: white;
	border: 2px solid darkorange;
	transition: 0.2s ease-in-out;
}

.pmt-articles-links a:hover {
	color: #fff;
	background-color: darkorange;
	border: 2px solid white;
}

.recent-edition-wrapper-outer {
	background-attachment: fixed;
	background-image: url("https://lpdf.in/images/pmt/collage.jpg");
	background-size: cover;
	background-repeat: no-repeat;
}

.recent-edition-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding-top: 70px;
	padding-bottom: 70px;
	max-width: 550px;
	margin-left: auto;
	margin-right: auto;
	background-color: white;
	border-radius: 20px;
	-webkit-box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
	-moz-box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
	box-shadow: 0 0 25px 0 rgba(0,0,0,0.2);
	transition: 0.2s ease-in-out;
}

.recent-edition-wrapper:hover {
	-webkit-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
	box-shadow: 0px 0px 30px 0px rgba(0,0,0,0.3);
}

.recent-edition-image {
	border: 10px solid white;
	-webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.2); 
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
	border-radius: 5px;
	box-sizing: content-box;
}

.recent-edition-text-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

.recent-edition-image-wrapper {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
}

.recent-edition-header {
	position: relative;
	display: flex;
	flex-direction: column;
	color: darkorange;
}

.recent-edition-header:after {
	content: '';
	width: 100px;
	border-top: 3px solid darkorange;
	margin-top: 10px;
	margin-bottom: 10px;
}

.recent-edition-subheader {
	color: #666;
}

.recent-edition-detail-text {
	font-family: 'lato';
	font-weight: 400;
	font-size: 18px;
}

.recent-edition-buttons {
	display: flex;
	flex-direction: column;
}

.recent-edition-index-wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.recent-edition-index {
	list-style: none;
	padding-inline-start: 0;
}

.recent-edition-index li {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.recent-edition-index-text {
	display: flex;
    flex-direction: column;
    position: relative;
	margin: 0;
	font-family: 'lato';
	font-weight: 400;
	font-size: 22px;
	margin-left: 10px;
}

.recent-edition-index-subtext {
	font-family: 'lato';
	font-weight: 400;
	font-size: 18px;
	margin-left: 10px;
}

.recent-edition-index-text::after {
	content: '';
	background: inherit;
	border-top: 2px solid;
	border-color: inherit;
    margin-top: 10px;
    width: 60px;
}

.prev-edition-wrapper {
	height: auto;
	max-width: 1150px;
}

.a1 {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
}

.e1 {
	transition: 0.2s ease-in-out;
	letter-spacing: 2px;
}

.b3 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-image: url("https://lpdf.in/images/pmt/bg2.jpeg");
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 10px;
	width: 500px;
	height: 350px;
	margin: 80px 20px 50px 20px;
	transition: 0.2s linear;
}

.b3:hover {
	-webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
	-moz-box-shadow: 0 0 15px 0 rgba(0,0,0,0.2); 
	box-shadow: 0 0 15px 0 rgba(0,0,0,0.2);
}

.b2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.b1 {
	position: relative;
	color: #fff;
	text-align: center;
	border-top-left-radius: 2px;
	border-bottom-right-radius: 2px;
	background-color: white;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
	transform: rotate(2.5deg);
	transition: 0.2s ease-in-out;
}

.b3:hover .b1{
	transform: rotate(-2.5deg);
}

.b3:hover .c1{
	transform: rotate(5deg);
}

.b2:hover .e1{
	color: #ff7700;
}

.c1 {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	border: solid 10px white;
	overflow: hidden;
	border-top-left-radius: 2px;
	border-bottom-right-radius: 2px;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.3);
	transform: rotate(-5deg);
	transition: 0.2s ease-in-out;
}

.d1 {
	height: auto;
	width: auto;
	max-height: 280px;
	max-width: 300px;
	transition: 0.2s ease-in-out;
	box-sizing: content-box;
}

.f1 {
	cursor: pointer;
	color: black;
}

.pmt-cover-img-links-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.pmt-cover-img-links-wrapper a{
	cursor: pointer;
	color: black;
	margin: 5px 10px 5px 10px;
	font-size: 0.9rem;
	font-weight: 600;
	font-family: 'lato';
	text-decoration: none;
	transition: 0.2s ease-in-out;
}

.pmt-cover-img-links-wrapper a:hover{
	color: #ff7700;
}

.customNavigation {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.customNavigation-first a{
	border-right: 1px solid darkorange;
}

.customNavigation-mid a{
	border-left: 1px solid darkorange;
	border-right: 1px solid darkorange;
}

.customNavigation-last a{
	border-left: 1px solid darkorange;
}

.customNavigation a{
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	text-decoration: none;
	padding: 5px 15px 5px 15px;
	margin: 7px 20px 7px 0px;
	border-radius: 50px;
	cursor: pointer;
	color: #fff;
	font-weight: 500;
	background-color: darkorange;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
	transition: 0.2s ease-in-out;
}

.customNavigation a:visited {
    transition: color .15s ease-in;
    background-color: hotpink;
}

.customNavigation a:focus, .customNavigation a:active {
    outline: none;
    text-decoration: none;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
    background-color: orange;
}

.customNavigation a:hover {
    outline: none;
    text-decoration: none;
	-webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
	-moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.4);
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 310px) {
	.card__image-container-pmt {
		height: 170px;
	}
}

@media (min-width: 450px) {
	.pmt-video-iframe iframe {
		width: 400px;
		height: 230px;
	}
}

@media (min-width: 544px) {
	.arrow-left {
		 left: 7vw;
	}
}

@media (min-width: 576px) {
	.recent-edition-buttons {
		flex-direction: row;
	}
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
	.pmt-video-iframe iframe {
		width: 450px;
		height: 255px;
	}
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 992px) {
	.pmt-recent-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.list-and-charges {
		flex-direction: row;
	}
	.pmt-recent-wrapper-inner {
		max-width: 1200px;
	}
	.recent-edition-wrapper {
		flex-direction: row;
		max-width: 950px;
		align-items: flex-start;
	}
	.recent-edition-image {
		width: 300px;
	}
	.recent-edition-text-wrapper {
		margin-left: 50px;
		max-width: 750px;
	}
	.pmt-archives-wrapper {
		margin-bottom: 100px;
	}
	.recent-edition-index-wrapper {
		flex-direction: row;
	}
	.pmt-video-iframe iframe {
		width: 600px;
		height: 340px;
	}
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 1100px) {
	.recent-edition-wrapper {
		min-width: 1000px;
	}
}

@media (min-width: 1200px) {
	.recent-edition-index-subtext {
		max-width: 400px;
	}
	.recent-edition-wrapper {
		min-width: 1150px;
	}
}

@media (min-width: 1300px) {
	.recent-edition-wrapper {
		min-width: 1250px;
	}
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 1400px) {
	.card__image-container-pmt {
		height: 180px;
	}
}