.footer-wrapper {
	padding-left: 7.5%;
	padding-right: 7.5%;
	height: auto;
	color: white;
}

.footer-main {
	display: flex;
	flex-direction: row;
	margin-bottom: 100px;
}

.social-media {
	width: 20%;
}

.quick-links {
	width: 75%;
}

.quick-links-inner {
	padding-top: 1px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.quick-links-inner-items-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.quick-links-inner-items-projects {
}

.vertical-bar {
	width: 1px;
	background-color: white;
	margin-top: 20px;
	margin-bottom: -10px;
}

.quick-links-header  {
	margin-top: 20px;
	margin-bottom: 20px;
}

.link {
	text-decoration: none;
	color: #fff;
}

.link:focus, .link:hover, .link:visited, .link:link, .link:active {
	text-decoration: none;
	color: #fff;
}

.link-item {
	margin-bottom: 10px;
	cursor: pointer;
}

.link-item:hover .link{
	animation: colorChange 0.5s ease;
	animation-fill-mode: forwards;	
}

@keyframes colorChange {
	100%{
		color: darkorange;
	}
}

.footer-end {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 0px;
	font-size: 0.7em;
}

@media screen and (max-width: 1300px) {
	.footer-wrapper {
		padding-left: 5%;
		padding-right: 5%;
	}
}

@media screen and (max-width: 1100px) {
	.footer-main {
		display: flex;
		flex-direction: column;
		margin-bottom: 100px;
	}

	.vertical-bar {
		display: none;
	}

	.quick-links-inner {
		display: flex;
		flex-direction: column;
	}

	.quick-links-inner-items-wrapper {
		width: 80%;
		justify-content: space-between;
	}

	.quick-links-header  {
		margin-top: 50px;
	}

	.quick-links-main-header {
		display: none;
	}
}


@media screen and (max-width: 768px) {
	.footer-main {
		display: flex;
		flex-direction: column;
		margin-bottom: 100px;
	}

	.quick-links-inner {
		padding-top: 1px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.quick-links-inner .quick-links-inner-items:nth-child(4) h3{
		display: none;
	}

	.quick-links-inner-items-wrapper {
		width: 100%;
		justify-content: space-between;
	}

	.social-media {
		width: auto;
		margin-bottom: 20px;
	}

	.social-media-icons img {
		width: 50%;
		height: 50%;
	}
}

@media screen and (max-width: 576px) {
	.quick-links-inner-items-wrapper {
		flex-direction: column;
		justify-content: flex-start;
	}

	.pseudo-dot {
		display: none;
	}
}
